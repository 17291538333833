@import '../../utils/variables';

$fixed-menu-height: 8rem;
$mobile-menu-width: 30rem;

.navbar {
	height: $fixed-menu-height;
	background-color: $secondary;
}

#navbar-logo {
	padding: 0;

	img {
		max-width: calc(#{$mobile-menu-width} - 2rem);
	}
}

// xl
@media only screen and (min-width: 1200px) {
	.navbar {
		height: auto;
		padding: 0;

		.dropdown-toggle {
			&::after {
				content: url("../../../../../images/ikony/arrow.svg");
				border: none;
				vertical-align: -0.85rem;
			}
		}
	}

	#navbar-logo {
		img {
			max-width: 17rem;
			vertical-align: unset;
		}
	}

	#navbar-overlay {
		opacity: 0;
	}

	#menu {
		position: relative;
	}

	.sticky-xl-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}

	#navbar-menu {
		text-transform: uppercase;
		font-weight: 600;
		line-height: 1.8rem;
		font-size: 1.6rem;

		.nav-item {
			align-items: center;
			display: flex;
			position: static;
			&:last-child {
				display: none;
			}

			&.active {
				background: rgba($primary, .9);
			}
			&.active.parent {
				background: rgba($primary, .9);
				.active {
					.dropdown-item {
						background: $light-color;
					}
				}
			}

			&:first-child {
				.nav-link {
					padding-bottom: 0;
				}
			}

		}

		.nav-link {
			padding: 2.3rem .9rem;
			color: white;
			min-height: 7.4rem;

			&:not(.dropdown-toggle) {
				padding-top: 2.5rem;
				padding-bottom: 2.1rem;
			}

			&:hover {
				color: $light-color;
				background: $primary;
			}
		}

		.nav-child.show {
			max-width: 100%;
			margin-top: 7.4rem;
			display: flex;
			justify-content: flex-end;
			padding: 0;
			border-radius: 0;
			border: none;
			box-shadow: 0 3px 16px 0 rgba(#000, 0.4);
			left: 50%;
			transform: translateX(-50%);
			z-index: 10;

			.nav-item {
				align-items: center;
				display: flex;
				width: calc(1440px / 7);
				height: 10.9rem;

				&:not(:first-child)::before {
					content: "";
					position: absolute;
					width: 3px;
					height: 100%;
					background: $primary;
					z-index: 99;
				}

				.dropdown-item {
					position: relative;
					padding: 0 2rem;
					text-align: center;
					white-space: normal;
					height: 100%;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					display: flex;

					span {
						width: 4.6rem;
						height: 1px;
						background: $light-secondary-color;
						margin-top: 1rem;
					}

					&:hover,
					&:focus {
						background: $light-color;
						color: $primary;

						&::before {
							background: $primary;
						}

					}
				}

			}
		}


	}
}

// xs, sm, md, lg
@media only screen and (max-width: 1199.98px) {
	.navbar {
		background: transparent !important;
		width: 0;
		left: 1.5rem;
		position: fixed;
		top: 0;
		z-index: $zindex-fixed;
	}

	#menu {
		position: fixed;
		top: 0;
		left: 0;
		padding-top: $fixed-menu-height !important;
		padding-top: 0 !important;
		height: 100vh !important;
		width: $mobile-menu-width;
		overflow-y: auto;
		background: $secondary;

		&>* {
			min-width: 100%;
		}
	}

	.transition-standard {
		transition: all 0.3s ease-in-out;
	}

	.navbar-toggler {
		outline: none !important;
		z-index: 1;
		background: white;
		padding: 0;
		height: 4rem;
		width: 4rem;
		box-sizing: content-box;
		line-height: 0;
		border-radius: 50%;
		.navbar-toggler-icon {
			&::before, &::after {
				background-color: $secondary;
			}
		}

		&.collapsed {
			background: $primary;
			.navbar-toggler-icon {
				&::before, &::after {
					background-color: white;
				}
			}
		}

		&:hover {
			opacity: 0.8;
		}
	}

	.navbar-toggler-icon {
		background-image: none !important;
		background: transparent;
		height: 0.2rem;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 1.5rem;

		&::before {
			transform: rotate(45deg);
			top: 0;
		}

		&::after {
			transform: rotate(-45deg);
			bottom: 0;
		}

		&::after,
		&::before {
			content: "";
			display: block;
			height: 0.2rem;
			position: absolute;
			width: 1.5rem;
			transform-origin: center;
		}
	}

	.navbar-toggler.collapsed .navbar-toggler-icon {
		&::before {
			transform: none;
			top: -0.7rem;
		}

		&::after {
			transform: none;
			bottom: -0.7rem;
		}

		background: white;
	}

	.navbar-toggler.collapsed {
		&~#menu {
			transform: translateX(-#{$mobile-menu-width});
		}

		&~#navbar-overlay {
			opacity: 0;
			visibility: hidden;
		}
	}

	#menu.collapse {
		&:not(.show) {
			display: block !important;
		}
	}

	.collapse {
		display: block;
	}

	#navbar-overlay {
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		top: 0;
		left: 0;
		transition: all 0.3s ease-in-out;
		opacity: 1;
		visibility: visible;
		z-index: -1;
	}

	#navbar-menu {
		margin-left: -1rem;
		margin-right: -1rem;
		width: calc(100% + 1rem);
		position: relative;

		.nav-item {
			align-items: center;
			display: flex;
			position: static;
			transition: all 0.3s ease-in-out;

			.nav-link,
			.dropdown-item {
				padding: 1rem 0 1rem 3rem;
				width: 100%;
				margin-top: -0.1rem;
				color: white;
			}

			&+.nav-item .nav-link {
			}

			&.divider {
				display: none;
			}

			&.active {
				background: $primary;
			}

			&.active.parent {
				background: $primary;
			}

			&:hover,
			&:focus {
				background: rgba($secondary, .5);
			}

			&.dropdown.show {
				transition: none;
				z-index: 1;
				top: 0;
				width: 100%;
				height: 100%;
				background: $primary;
				display: block;
				
				.nav-link {
					padding-left: 5rem ;
					margin-top: -0.1rem;
				}

				.nav-item {
					a {
						padding-left: 3rem;
					}
				}

				.dropdown-toggle::after {
					transform: rotate(180deg);
					padding-left: 3rem;
				}

				.dropdown-menu {
					height: auto;
					background: white;
					width: 100%;
				}
			}

			.dropdown-toggle::after {
				float: right;
				content: url(../../../../../images/ikony/arrow.svg);
				border: none;
				max-height: 1.9rem;
				padding-right: 3rem;
			}
		}

		.dropdown-menu {
			padding: 0;
			margin: 0;
			border: none;
			background: transparent;
			margin-top: -0.1rem;
			height: 0;
			transition: all 0.3s ease-in-out;

			.dropdown-item {
				color: $primary;

				&.active,
				&:hover,
				&:focus {
					color: white;
					background: transparent;
				}
			}

			.nav-item.active .dropdown-item {
				color: white;

			}
		}
	}
}
